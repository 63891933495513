import { Layer, Marker, Source, useMap } from "react-map-gl"
import { calculateMouseDistance, distanceRulerLine, distanceRulerPosition, distanceRulerTrigger, mouseDistanceLineFeatures, moveRulerKnob, treeLineDistanceFeatures } from "../../appState/distanceSignals"
import { useEffect } from "react"
import { maximumDistances, minimumDistanceArea, showDistances } from "../../appState/legalSignals"
import { IoPinSharp } from "react-icons/io5"
import { Box } from "@mui/material"

const DistanceMeasurementsSource: React.FC = () => {
    // get a reference of the map
    const map = useMap()

    // add a effect to listen to mouseMove events
    useEffect(() => {
        if (!map.current) return

        // add a listener to the map
        map.current.on('mousemove', calculateMouseDistance)

        return () => {
            map.current!.off('mousemove', calculateMouseDistance)
        }
    }, [])

    return <>
        {/* MOUSE DISTANCE */}
        <Source
            id="mouse-distances"
            type="geojson"
            data={{
                type: 'FeatureCollection',
                features: mouseDistanceLineFeatures.value
            }}
        >
            <Layer 
                id="mouse-distances"
                source="mouse-distances"
                type="line"
                paint={{
                    'line-color': 'grey',
                    'line-dasharray': [2, 2],
                    'line-width': 2
                }}
            />
            <Layer 
                id="mouse-distances-label"
                source="mouse-distances"
                type="symbol"
                layout={{
                    "text-field": ["get", "label"],
                    "text-size": 16,
                    "text-allow-overlap": true,
                    "text-anchor": "top",
                    "symbol-placement": "line-center"
                }}
            />
        </Source>

        {/* TREE LINE DISTANCE */}
        <Source
            id="tree-line-distances"
            type="geojson"
            data={{type: 'FeatureCollection', features: treeLineDistanceFeatures.value}}
        >
            <Layer 
                id="tree-line-distances"
                source="tree-line-distances"
                type="line"
                paint={{
                    'line-color': 'grey',
                    'line-dasharray': [2, 2],
                    'line-width': 2
                }}
            />
            <Layer 
                id="tree-line-distances-label"
                source="tree-line-distances"
                type="symbol"
                layout={{
                    "text-field": ["get", "label"],
                    "text-size": 16,
                    "text-allow-overlap": true,
                    "text-anchor": "top",
                    "symbol-placement": "line-center"
                }}
            />
        </Source>

        {/* Minimum distance source */}
        <Source
            id="minimum-distance"
            type="geojson"
            data={minimumDistanceArea.value}
            generateId
        >
            <Layer 
                id="minimum-distance"
                source="minimum-distance"
                beforeId="tree-locations"
                type="fill"
                layout={{
                    visibility: showDistances.value ? 'visible' : 'none'
                }}
                paint={{
                    'fill-color': 'red',
                    'fill-opacity': 0.8
                }}
            />
        </Source>

        {/* maximum distance source */}
        <Source
            id="maximum-distances"
            type="geojson"
            data={maximumDistances.value}
            generateId
        >
            <Layer 
                id="maximum-distances"
                source="maximum-distances"
                beforeId="tree-locations"
                type="line"
                layout={{
                    visibility: showDistances.value ? 'visible' : 'none'
                }}
                paint={{
                    'line-color': 'red',
                    'line-width': 3
                    }}
            />
            <Layer 
                id="maximum-distance-label"
                source="maximum-distances"
                beforeId="tree-locations"
                type="symbol"
                layout={{
                    "text-field": ["get", "label"],
                    "text-size": 16,
                    "text-allow-overlap": true,
                    "text-anchor": "top",
                    "symbol-placement": "line-center",
                    "visibility": showDistances.value ? 'visible' : 'none'
                
                }}
            />
        </Source>

        <Source id="distance-ruler" type="geojson" data={distanceRulerLine.value}>
                <Layer 
                    id="distance-ruler-line"
                    source="distance-ruler"
                    beforeId="tree-locations"
                    type="line"
                    paint={{
                        'line-color': 'black',
                        'line-width': 3,
                        'line-dasharray': [2, 2]
                    }}
                    layout={{visibility: distanceRulerTrigger.value ? 'visible' : 'none'}}
                />
                <Layer 
                    id="distance-ruler-label"
                    source="distance-ruler"
                    beforeId="tree-locations"
                    type="symbol"
                    layout={{
                        "text-field": ["get", "label"],
                        "text-size": 20,
                        "text-allow-overlap": true,
                        "text-anchor": "top",
                        "symbol-placement": "line-center",
                        "visibility": distanceRulerTrigger.value ? 'visible' : 'none'
                    }}
                />
        </Source>

        { distanceRulerTrigger.value ? distanceRulerPosition.value.map((pos, i) => (
            <Marker 
                key={i} 
                longitude={pos.lon} 
                latitude={pos.lat}
                draggable
                onDrag={e => moveRulerKnob(i, e)}
                 anchor="center"
            >   
                <Box sx={{'&:hover': {color: 'darkred', fontSize: '38px'}}}>
                    <IoPinSharp size="35px" />
                </Box>
            </Marker>
        )) : null}
    </>
}

export default DistanceMeasurementsSource