import { ListItemIcon, MenuItem, MenuItemProps, Typography } from "@mui/material"
import { useState } from "react"
import { calculatedTreeLines, treeLocationFeatures, treeLocations } from "../../appState/geoJsonSignals"
import { FileDownload } from "@mui/icons-material"
import cloneDeep from "lodash.clonedeep"
import { referenceArea } from "../../appState/referenceAreaSignals"

const GeodataDownloadMenuItem: React.FC<MenuItemProps & {clickCallback: () => void}> = ({ clickCallback, ...itemProps }) => {
    // add state to track processing
    const [isProcessing, setProcessing] = useState<boolean>(false)
    
    const onClick = () => {
        setProcessing(true)

        // build in-place geojson of the treeLocation
        // const geodata = {
        //     layers: [
        //         cloneDeep(treeLocations.value),
        //         cloneDeep(referenceArea.value),
        //         cloneDeep(calculatedTreeLines.value)
        //     ]
        // }
        const geodata = cloneDeep(treeLocations.value)

        // develpment
        console.log(geodata)

        // create a blob of it
        const blob = new Blob([JSON.stringify(geodata, null, 4)], { type: "application/json" })

        // create a link, add it into the DOM, click and remove it again
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `tree-locations-${new Date().toISOString().split('T')[0]}.geojson`
        link.click()
        link.remove()


        // finally call the callback of the parent element
        setProcessing(false)
        clickCallback()
    }
    
    return <>
    <MenuItem {...itemProps} onClick={onClick} disabled={isProcessing || treeLocationFeatures.value.length === 0}>
        <ListItemIcon>
            <FileDownload fontSize="small" />
            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                { isProcessing ? 'rechnet...' : 'Geodaten herunterladen' }
            </Typography>
        </ListItemIcon>
    </MenuItem>
    </>
}

export default GeodataDownloadMenuItem