import Plot from "react-plotly.js"
import { simulationTimeSteps, treeSpecies } from "../../../appState/backendSignals"
import { biomassMode, biomassProperty, biomassSimulation } from "../../../appState/biomassSimulationSignals"
import { referenceAreaHectar } from "../../../appState/referenceAreaSignals"
import { Data } from "plotly.js"
import { simulationStep } from "../../../appState/simulationSignals"

const getColor = (treeType: string, variable: 'agb' | 'carbon') => {
    const species = treeSpecies.value.find(t => t.latin_name === treeType)!
    
    // Above ground Bionmass
    if (variable === 'agb') {
        if (species.type === 'Baum') {
            return '#8B9B82'
        } else {
            return '#AAC59B'
        }
    } 
    
    // Carbon content
    else {
        if (species.type === 'Baum') {
            return '#C4977E'
        } else {
            return '#D7B9A8'
        }
    }
}

const BiomassResultPlot: React.FC = () => {
    return <>
        <Plot
            style={{width: '100%'}}
            layout={{
                height: 300, 
                margin: {t: 10, r: 15},
                autosize: true,
                showlegend: false,
                xaxis: {title: 'Jahre', range: [0, 99]},
                yaxis: {title: biomassProperty.value === 'agb' ?  `Biomasse (${biomassMode.value === 'total' ? 't' : 't / ha'})` : `Kohlenstoff (${biomassMode.value === 'total' ? 't' : 't / ha'})`}
            }}
            data={[
                ...Object.entries(biomassSimulation.value).filter(([key, _]) => key !=='total').map(([treeType, values], idx) => {
                    return {
                        type: 'scatter',
                        mode: 'lines',
                        x: simulationTimeSteps.value,
                        y: values.map(v => biomassMode.value === 'total' ? v / 1000 : (v / 1000) / referenceAreaHectar.value),
                        hovertemplate: `${treeSpecies.peek().find(t => t.latin_name === treeType)!.german_name}<br>nach %{x} Jahren<br>Biomasse: %{y:.2f} ${biomassMode.value === 'total' ? 't' : 't / ha'}<extra></extra>`,
                        fill: 'tonexty',
                        line: { width: 2, color: getColor(treeType, biomassProperty.value)},
                        stackgroup: 'one'
                    } as Data
                }),
                {
                    type: 'scatter',
                    mode: 'markers',
                    x: [simulationStep.value.current],
                    y: [biomassSimulation.value.total[simulationTimeSteps.value.filter(t => t <= simulationStep.value.current).length] / 1000 / (biomassMode.value === 'total' ? 1 : referenceAreaHectar.value)],
                    marker: {
                        color: biomassProperty.value === 'agb' ? '#462e17' : '#241e19', 
                        size: 15
                    },
                    hovertemplate: `Momentan (%{x} Jahre)<br>Biomasse: %{y:.2f} ${biomassMode.value === 'total' ? 't' : 't / ha'}<extra></extra>`,
                }
            ]}
            config={{displayModeBar: false}}
            
        />
    </>
}

export default BiomassResultPlot