/**
 * This module collects the signals that are needed to handle the copy-paste functionality.
 * That also includes the moving and strainghtening of tree lines.
 */
import { computed, signal } from "@preact/signals-react";
import { rawTreeLocationSeedData, treeFeatures } from "./treeLocationSignals";
import { nanoid } from "nanoid";
import { along, length, lineString, toMercator, toWgs84 } from "@turf/turf";
import { calculatedTreeLineFeatures } from "./treeLineSignals";

// add state triggers for copy-paste, straightening and moving tree lines
export const enableMoveKnobsTrigger = signal<boolean>(true)



interface MoveKnobProperties {
    treeLineId: string
}
type MoveKnob = GeoJSON.Feature<GeoJSON.Point, MoveKnobProperties>

// whenever a new tree-line is created, we need to add a 'move' knob to the south end of the line
export const treeLineMoveKnob = computed<MoveKnob[]>(() => {
    // create a container for the features
    const features: MoveKnob[] = []

    // if the moveKnobs are disabled, return an empty array
    if (!enableMoveKnobsTrigger.value) return features

    // get the tree features
    const trees = treeFeatures.value

    // derive the lines from that
    const linesIds = trees.map(t => t.properties.treeLineId!).filter((lId, index, arr) => arr.indexOf(lId) === index)

    // for each line, add a move knob
    linesIds.forEach(lineId => {
        // get all trees that belong to this line
        const lineTrees = trees.filter(tree => tree.properties.treeLineId === lineId)
        
        // we need at least two trees to create a line
        if (lineTrees.length > 1) {
            // get all trees that belong to this line and filter for the smallest y-coordinate
            const southTree = lineTrees.sort((a, b) => a.geometry.coordinates[1] - b.geometry.coordinates[1])[0]

            // create the new feature
            features.push({
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: [...southTree.geometry.coordinates]
                },
                properties: {treeLineId: lineId}
            })
        }
    })
    
    // return the feature collection
    return features
})

// implement functions to move the lines 
export const offestTreeLine = (moveKnob: MoveKnob, newKnobPosition: [number, number]) => {
    // calculate the offset to the new knob position
    const offset = [newKnobPosition[0] - moveKnob.geometry.coordinates[0], newKnobPosition[1] - moveKnob.geometry.coordinates[1]]

    // get the tree features in question and move them
    const newSeeds = rawTreeLocationSeedData.value.map(seed => {
        // if that seed does not belong to the tree line, return it unchanged
        if (seed.treeLineId !== moveKnob.properties.treeLineId) return seed

        // otherwise, offset the seed position
        return {
            ...seed,
            location: {
                lng: seed.location.lng + offset[0],
                lat: seed.location.lat + offset[1]
            }
        }
    })

    // replace the seed data with the new seeds
    rawTreeLocationSeedData.value = newSeeds
}

// implement a function to copy-paste a tree line
export const copyPasteTreeLine = (treeLineId: string) => {
    // get the tree features in question
    const originalTrees = rawTreeLocationSeedData.value.filter(seed => seed.treeLineId === treeLineId)

    // create a new Id for the new tree line
    const newTreeLineId = nanoid(12)

    // get the next Id
    const nextId = rawTreeLocationSeedData.value.length + 1

    // create a container for the new trees
    const newTrees = originalTrees.map((tree, idx) => {
        // offset the location by 35 meters
        const mercatorPos = toMercator({type: 'Point', coordinates: [tree.location.lng, tree.location.lat]})
        mercatorPos.coordinates = [mercatorPos.coordinates[0] + 35, mercatorPos.coordinates[1] - 5]
        const offsetPos = toWgs84(mercatorPos)

        return {
            ...tree,
            treeLineId: newTreeLineId,
            id: `s${nextId + idx}`,
            location: {lng: offsetPos.coordinates[0], lat: offsetPos.coordinates[1]}
        }
    })

    // push the new trees to the seeds
    rawTreeLocationSeedData.value = [...rawTreeLocationSeedData.value, ...newTrees]
}

// implement a function to straighten a tree line
export const straightenTreeLine = (treeLineId: string) => {
    // get the tree features in question
    // here we use the calculated tree line, which is already in order
    const line = calculatedTreeLineFeatures.value.find(line => line.properties.id === treeLineId)
    if (!line) return
    // get the straight line from first to last point in the coordinates
    const start = line.geometry.coordinates[0]
    const end = line.geometry.coordinates[line.geometry.coordinates.length - 1]
    const straightLine = lineString([start, end])

    // get the trees in question and the planting step for the straightened line
    const trees = rawTreeLocationSeedData.value.filter(tree => tree.treeLineId === treeLineId)
    const num = trees.length
    const len = length(straightLine, {units: 'meters'})
    const step = len / (num - 1)

    // construct the new positions
    const newTrees = trees.map((tree, idx) => {
        const newCoords = along(straightLine, idx * step, {units: 'meters'}).geometry
        return {
            ...tree,
            location: {lng: newCoords.coordinates[0], lat: newCoords.coordinates[1]}
        }
    })

    // set the new trees seeds, but filter out the old positions
    rawTreeLocationSeedData.value = [
        ...rawTreeLocationSeedData.value.filter(t => t.treeLineId !== treeLineId),
        ...newTrees
    ]
}
