import { Marker } from "react-map-gl"
import { offestTreeLine, treeLineMoveKnob } from "../../appState/copyPaseSignals"
import { SlCursorMove } from 'react-icons/sl'

const CopyPasteSource: React.FC = () => {
    return <>
        { treeLineMoveKnob.value.map(moveKnob => {
            return (
                <Marker
                    longitude={moveKnob.geometry.coordinates[0]}
                    latitude={moveKnob.geometry.coordinates[1]}
                    key={moveKnob.properties.treeLineId}
                    anchor="top"
                    offset={[0, 15]}
                    draggable
                    //onDrag={e => console.log(e.lngLat)}
                    onDrag={e => offestTreeLine(moveKnob, [e.lngLat.lng, e.lngLat.lat])}
                    style={{fontSize: '1.5rem'}}
                >   
                    <SlCursorMove />
                </Marker>
        )
        }) }
    </>
}

export default CopyPasteSource