import { AppBar, Box, Toolbar, Typography } from "@mui/material"
import { posthog } from "../../tracking"
import { useSignal, useSignalEffect } from "@preact/signals-react"

interface AppHeaderProps {
    // the slots can be any kind of valid JSX
    leftSlot?: JSX.Element
    rightSlot?: JSX.Element
}

const AppHeader: React.FC<React.PropsWithChildren<AppHeaderProps>> = ({ children, leftSlot, rightSlot }) => {
  const rageClicks = useSignal(0)
  useSignalEffect(() => {
    if (rageClicks.value > 5) {
      posthog.capture('LogoRageClick', { count: rageClicks.peek() })
    }
  })

    return <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="transparent" style={{height:'72px'}}>
          <Toolbar style={{minHeight: '72px'}}>
            <Box width="100%" m={0} p={0} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">

              <Box display="flex" flexDirection="row" alignItems="center">
                { leftSlot || (<>
                    <img style={{height: '56px', width: 'auto'}} src="icons/logo.png" alt="Integra Logo" onClick={() => rageClicks.value += 1} />
                    <Typography ml={1} variant="h6" component="div">
                    AGROFORST-PLANUNGSTOOL
                    </Typography>
                </>)}
              </Box>

              <Box>
                { children }
              </Box>

              <Box sx={{ marginRight: '8px', minWidth: '200px' }}>
                <Box sx={{ display: "flex" }}>
                  { rightSlot }
                </Box>
              </Box>
            </Box>

          </Toolbar>
        </AppBar>
      </Box>
    </>
}

export default AppHeader