import { useEffect, useState } from "react"
import packageConfig from "../../../package.json"
import localforage from "localforage"
import semver from "semver"
import { Button, Card, CardActionArea, CardActions, CardContent, Modal, Paper } from "@mui/material"
import ReactMarkdown from "react-markdown"

const appVersion = packageConfig.version

const AppVersion: React.FC =() => {
    const [clientVersion, setClientVersion] = useState<string | undefined>(undefined)
    const [hasMessage, setHasMassage] = useState<boolean>(false)
    const [releaseNotes, setReleaseNotes] = useState<string | undefined>(undefined)

    // control flow for the modal
    const [open, setOpen] = useState<boolean>(false)
    useEffect(() =>  setOpen(hasMessage && !!releaseNotes), [releaseNotes, hasMessage])

    // load the current version from the backend and compare
    useEffect(() => {
        localforage.getItem<string>('clientVersion').then(value => {
            // if no value was set, we assume 0.0.0
            if (!value || !semver.valid(value)) {
                value = '0.0.0'
            }
            setClientVersion(value)

            // check if the app has been updated since the user was last seen
            if (semver.gt(appVersion, value)) {
                // inform the applciation to show the update info
                setHasMassage(true)

                // save the current version to the local storage
                localforage.setItem('clientVersion', appVersion)
            }
        })
    }, [appVersion])

    // if there is a message, load the release notes
    useEffect(() => {
        if (hasMessage && appVersion) {
            fetch(`releaseNotes/v${appVersion}.md`)
            .then(response => response.blob())      // get the file blob
            .then(blob => blob.text())              // read as text
            .then(text => setReleaseNotes(text))
        }
    }, [hasMessage, appVersion])

    return <>
    <Modal open={open} onClose={() => setOpen(false)}>
        <Paper 
            elevation={6} 
            sx={{
                with: '80vw',
                maxWidth: '1200px',
                // maxHeight: '80vh',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                // bgcolor: 'background.paper',
                // border: '2px solid background.paper',
                // boxShadow: 24,
                // p: 4,
                // overflowY: 'auto'
            }}
        >
            <Card>
                <CardContent sx={{maxHeight: '80vh', overflowY: 'auto'}}>
            <ReactMarkdown>
                {'# Release Notes \n Neue Version `' + clientVersion + '` --> `' + appVersion + '`\n' + releaseNotes}
            </ReactMarkdown>
            </CardContent>
            <CardActions>
                <Button onClick={() => setOpen(false)} color="primary" variant="text">Schließen</Button>
            </CardActions>
            </Card>
        </Paper>
    </Modal>
    </>
}

export default AppVersion