import { useEffect, useState } from "react"
import { changeStaticData, protectionStatus, staticData } from "../../appState/summarySignals"
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"

const ProtectionAreaSelect: React.FC = () => {
    const [selectedUse, setSelectedUse] = useState<string[]>(staticData.value.protectionStatus || [])
    
    // handle the change when a checkbox was clicked
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        if (e.target.checked) {
            if (protectionStatus[index] === 'Keine') {
                setSelectedUse(['Keine'])
            } else {
                setSelectedUse([...selectedUse.filter(u => u !== 'Keine'), protectionStatus[index]])
            }
        } else {
            setSelectedUse(selectedUse.filter(u => u !== protectionStatus[index]))
        }
    }

    // update the summaryData when the selectedUse changes
    useEffect(() => {
        changeStaticData('protectionStatus', selectedUse)
    }, [selectedUse])

    return <>
        <FormGroup>
            {protectionStatus.map((usage, index) => (
                <FormControlLabel 
                    key={index}
                    control={<Checkbox 
                            checked={selectedUse.includes(protectionStatus[index])} 
                            onChange={e => handleChange(e, index)}
                        />
                    }
                    label={usage}
                />
            ))}
            
        </FormGroup>
    </>
}

export default ProtectionAreaSelect