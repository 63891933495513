import { useComputed } from "@preact/signals-react"
import { editTreeLineId } from "../../appState/treeLocationSignals"
import { calculatedTreeLineFeatures } from "../../appState/treeLineSignals"
import { ListItemAvatar, ListItemButton, ListItemText, Skeleton } from "@mui/material"
import { treeLocationFeatures } from "../../appState/geoJsonSignals"

const NewTreeLineInfo: React.FC = () => {
    // this info informs about creating a new tree line, or single tree
    // for this, we need to check if editTreeLineId is set and if yes,
    // if it is part of the calculatedTreeLineFeatures
    const mode = useComputed<'single' | 'new' | 'off'>(() => {
        // check if the treeLine is in the calculatedTreeLineFeatures
        if (calculatedTreeLineFeatures.value.map(l => l.properties.id).includes(editTreeLineId.value)) {
            return 'off'
        } else if (editTreeLineId.value && !!treeLocationFeatures.value.find(t => t.properties.treeLineId === editTreeLineId.value)) {
            return 'single'
        } else {
            return 'new'
        }
    })
    return <>
        { mode.value === 'new' ? (
            <ListItemButton selected>
                <ListItemAvatar>
                    <Skeleton variant="circular" width={40} height={40} />
                </ListItemAvatar>
                <ListItemText primary="Neue Baumreihe" secondary="Füge weitere Bäume hinzu, damit hier eine Reihe oder ein Einzelbaum entsteht" />
            </ListItemButton>
        ): null }
        { mode.value === 'single' ? (
            <ListItemButton selected>
            <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText primary="Neue Baumreihe" secondary="Füge für eine Reihe weitere Bäume hinzu, oder wähle 'Neu'" />
        </ListItemButton>
        ) : null }
    </>
}

export default NewTreeLineInfo