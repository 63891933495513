/**
 * Main signal for saving the state of the summary page
 */

import { computed, signal } from "@preact/signals-react";
import { activePage } from "./appViewSignals";
import { referenceAreaHectar } from "./referenceAreaSignals";
import { agriculturalArea } from "./treeLineSignals";
import { area } from "@turf/turf";
import { treeLocationFeatures } from "./geoJsonSignals";

export const agriculturalUse = ['Ackerbau', 'Dauerkultur', 'Dauergrünland'] as const
export type AgriculturalUse = typeof agriculturalUse[number] 

export const forestryUse = ['Kurzumtrieb', 'Streuobst', 'Wertholz', 'Futterlaub', 'Bienenweide'] as const
export type ForestryUse = typeof forestryUse[number]

export const protectionStatus = ['Keine', 'Wasserschutzgebiet', 'Überschwemmungsgebiet', 'Naturschutzgebiet'] as const
export type ProtectionStatus = typeof protectionStatus[number]

export interface StaticSummaryData {
    systemTitle: string
    agriculturalUse: AgriculturalUse,
    forestryUse: ForestryUse[],
    protectionStatus: ProtectionStatus[],
    precipitationSum: number,
    averageTemperature: number,
    soilType: string,
    soilMoisture: number,
    soilpH: number
}

export const rawStaticData = signal<StaticSummaryData>({
    systemTitle: 'Mein Agroforstsystem',
    precipitationSum: 600,
    averageTemperature: 10.3,
    soilType: 'Schluffton',
    soilMoisture: 5,
    soilpH: 7,
    forestryUse: ['Wertholz'],
    agriculturalUse: 'Ackerbau',
    protectionStatus: ['Keine']
    
})

export const staticData = computed<StaticSummaryData>(() => rawStaticData.value)

export interface SummaryData extends StaticSummaryData {
    referenceArea: number,
    agriculturalArea: number,
    agb: number,
    carbon: number,
    nectar: number,
    pollen: number
}

export const changeStaticData = (label: keyof SummaryData, value: string | string[] | number) => {
    rawStaticData.value = {
        ...rawStaticData.peek(),
        [label]: value
    }
}


export const summaryData = computed<SummaryData | undefined>(() => {
    // if the main tab is not on the summary, return undefined
    if (activePage.value !== 'summary') return undefined

    // calculate above ground biomass
    const agb = treeLocationFeatures.value
        .filter(t => t.properties.age! > 0 && (!t.properties.harvestAge || t.properties.age! < t.properties.harvestAge))
        .reduce((prev, curr) => prev + (curr.properties.agb || 0), 0)

    // calculate carbon
    const carbon = treeLocationFeatures.value
        .filter(t => t.properties.age! > 0 && (!t.properties.harvestAge || t.properties.age! < t.properties.harvestAge))
        .reduce((prev, curr) => prev + (curr.properties.carbon || 0), 0)

    // calculate nectar
    const nectar = treeLocationFeatures.value
        .filter(t => t.properties.age! > 0 && (!t.properties.harvestAge || t.properties.age! < t.properties.harvestAge))
        .reduce((prev, curr) => prev + (curr.properties.nectar || 0), 0)
    
    // calculate pollen
    const pollen = treeLocationFeatures.value
        .filter(t => t.properties.age! > 0 && (!t.properties.harvestAge || t.properties.age! < t.properties.harvestAge))
        .reduce((prev, curr) => prev + (curr.properties.pollen || 0), 0)

    // in any other case, return the summary data
    return {
        referenceArea: referenceAreaHectar.peek(),
        agriculturalArea: area(agriculturalArea.value) / 10000,
        agb,
        carbon,
        nectar,
        pollen,
        ...rawStaticData.value
    }
})

