import { ListItemIcon, MenuItem, MenuItemProps, Typography } from "@mui/material"
import { createProjectSnapshot } from "./project"
import { useState } from "react"
import { referenceArea } from "../../appState/referenceAreaSignals"
import { Save } from "@mui/icons-material"



const DownloadProjectMenuItem: React.FC<MenuItemProps & {clickCallback: () => void}> = ({ clickCallback,...itemProps }) => {
    // create a state for the blob
    const [isProcessing, setProcessing] = useState<boolean>(false)

    // the click handler
    const onClick = () => {
        setProcessing(true)
        const project = createProjectSnapshot()
        
        // for dev reasons
        console.log(project)

        // create a blob
        const blob = new Blob([JSON.stringify(project, null, 4)], { type: "application/json" })
        // const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `agroforst-planung-${new Date().toISOString().split('T')[0]}.json`
        link.click()
        link.remove()

        // open a new tab with this url
        //window.open(url, '_blank')?.focus()
        setProcessing(false)

        // finally call the callback of the parent element
        clickCallback()
    }
    return <>
        <MenuItem {...itemProps} onClick={onClick} disabled={isProcessing || referenceArea.value.features.length === 0}>
            <ListItemIcon>
                <Save fontSize="small" />
            </ListItemIcon>    
            <Typography variant="body2" sx={{color: 'text.secondary'}}>
                { isProcessing ? 'rechnet...' : 'Projekt speichern' }
            </Typography>
        
        </MenuItem>
    </>
}

export default DownloadProjectMenuItem