import { Box, Button, ButtonProps, Menu, MenuList } from "@mui/material"
import { useState } from "react"
import DownloadProjectMenuItem from "./DownloadProjectMenuItem"
import GeodataDownloadMenuItem from "./GeodataDownloadMenuItem"
import { ExpandLess, ExpandMore } from "@mui/icons-material"

const ProjectManagementButton: React.FC<ButtonProps> = ({ ...btnProps }) => {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null)
    const open = Boolean(anchor)

    // click handler
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchor(event.currentTarget)
    }

    const handleClose = () => {
        setAnchor(null)
    }
    
    return <>
        <Box>
            <Button
                {...btnProps}
                id="project-menu-button"
                aria-controls={open ? 'project-menu' : undefined }
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                endIcon={open ? <ExpandLess /> : <ExpandMore />}
            >
                Speichern
            </Button>
            <Menu
                id="project-menu"
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'project-menu-button',
                }}
            >
                <MenuList>
                    <DownloadProjectMenuItem clickCallback={handleClose} />
                    <GeodataDownloadMenuItem clickCallback={handleClose}/>
                </MenuList>
            </Menu>
        </Box>
    </>
}

export default ProjectManagementButton