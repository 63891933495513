import { useComputed } from "@preact/signals-react"
import Plot from "react-plotly.js"
import { shadeStats } from "../../../appState/shadeSimulationSignals"

const ShadeResultPlot: React.FC = () => {
    // calculate the two sizes every time they change
    const shadedArea = useComputed<number>(() => Math.round(shadeStats.value.shadedArea / 1000) / 10)
    const unshadedArea = useComputed<number>(() => Math.round((shadeStats.value.agriculturalArea - shadeStats.value.shadedArea) / 1000) / 10)

    return <>
        <Plot 
            layout={{
                width: 400,
                height: 350, 
                showlegend: true,
                margin: {t: 0, b: 15},
                legend: {orientation: 'h'},
            }}
            data={[{
                type: 'pie',
                hole: 0.4,
                values: [unshadedArea.value, shadedArea.value],
                labels: ['Unbeschattet', 'Beschattet'],
                textinfo: 'none',
                insidetextorientation: 'radial',
                hovertemplate: '%{customdata[0]}<extra></extra>',
                customdata: [
                    `<b>Unbeschattet</b><br>${unshadedArea.value} ha<br>${(unshadedArea.value / (shadedArea.value + unshadedArea.value) * 100).toFixed(0)}%`, 
                    `<b>Beschattet</b><br>${shadedArea.value} ha<br>${(shadedArea.value / (shadedArea.value + unshadedArea.value) * 100).toFixed(0)}%`, 
                ],
                marker: {
                    colors: ['#B4C4E4', '#628CCC'],
                    line: {
                        color: 'white',
                        width: 1
                    }
                }
            }]}
            config={{displayModeBar: false}}
        />
    </>
}

export default ShadeResultPlot