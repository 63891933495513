import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { useState } from "react"
import { treeLineAreaFeatures } from "../../appState/treeLineSignals"
import { useSignalEffect } from "@preact/signals-react"
import { treeLocationFeatures } from "../../appState/geoJsonSignals"
import { germanSpecies } from "../../appState/backendSignals"
import { Paper, TableContainer } from "@mui/material"

const columns: GridColDef[] = [
    { field: 'art', headerName: 'Art', minWidth: 250, flex: 1 },
    { field: 'count', headerName: 'Anzahl', minWidth: 150},
    { field: 'agb', headerName: 'Oberid. Biomasse', minWidth: 150},
    { field: 'calorific', headerName: 'Heizwert', minWidth: 150, valueFormatter: (val) => `${val} KWh`},
    { field: 'carbon', headerName: 'Oberid. Kohlenstoff', minWidth: 150, valueFormatter: (val) => `${val} kg`},
    { field: 'co2', headerName: 'CO2-Äquivalent', minWidth: 150, valueFormatter: (val) => `${val} kg`},
    { field: 'pollen', headerName: 'Pollenvolumen', minWidth: 150, valueFormatter: (val) => `${val} mm³`},
    { field: 'nectar', headerName: 'Nektarmenge', minWidth: 150, valueFormatter: (val) => `${val} ml`},
]

interface Row {
    art: string,
    count: number,
    agb: number,
    calorific: number,
    carbon: number,
    co2: number,
    pollen: number,
    nectar: number
}

const SingleTreeTable: React.FC = () => {
    // create a state to hold the current data
    const [rows, setRows] = useState<Row[]>([])
    
    // update the rows when the treeLocationFeatures change
    useSignalEffect(() => {
        // first we need a list of all treeLineIds that are in use
        const treeLineIds = treeLineAreaFeatures.value.map(l => l.properties.id)

        // container for the rows
        const treeSpecies: {[type: string]: Row} = {}

        // go for each tree and add it if it is a singleTree
        treeLocationFeatures.value.forEach(tree => {
            if (!treeLineIds.includes(tree.properties.treeLineId!)) {
                // check if we already have a row for this species
                if (!(tree.properties.treeType in treeSpecies)) {
                    treeSpecies[tree.properties.treeType] = {
                        art: tree.properties.treeType,
                        count: 1,
                        agb: tree.properties.agb || 0,
                        calorific: (tree.properties.agb || 0) * 4,
                        carbon: tree.properties.carbon || 0,
                        co2: (tree.properties.carbon || 0) * 3.67,
                        pollen: tree.properties.pollen || 0,
                        nectar: tree.properties.nectar || 0,
                    }
                } else {
                    // otherwise, just update the count
                    treeSpecies[tree.properties.treeType].count += 1
                    treeSpecies[tree.properties.treeType].agb += tree.properties.agb || 0
                    treeSpecies[tree.properties.treeType].calorific += (tree.properties.agb || 0) * 4
                    treeSpecies[tree.properties.treeType].carbon += tree.properties.carbon || 0
                    treeSpecies[tree.properties.treeType].co2 += (tree.properties.carbon || 0) * 3.67
                    treeSpecies[tree.properties.treeType].pollen += tree.properties.pollen || 0
                    treeSpecies[tree.properties.treeType].nectar += tree.properties.nectar || 0
                }
            }
        })

        // map the values into rows and do some last dataset-wide calculations
        setRows(Object.values(treeSpecies).map(row => {
            return {
                ...row,
                art: germanSpecies.peek()[row.art] || row.art,
                agb: Math.round(row.agb),
                calorific: Math.round(row.calorific),
                carbon: Math.round(row.carbon),
                co2: Math.round(row.co2),
                pollen: Math.round(row.pollen),
                nectar: Math.round(row.nectar)
            }
        }))
    })


    return <>
        <TableContainer sx={{ my: 2, bgcolor: "grey.100", width: '100%' }} component={Paper}>
            <DataGrid
                rows={rows.map((r, i) => ({...r, id: i}))}
                columns={columns}
                disableRowSelectionOnClick
                disableColumnMenu
                />
        </TableContainer>
    </>
}

export default SingleTreeTable