import { Box, Tab, Tabs, useMediaQuery } from "@mui/material";
import {  Summarize, Map, HelpCenter, Checklist } from "@mui/icons-material";

import MainMap from "../components/MainMap/MainMap";
import TreeLineSource from "../components/MainMap/TreeLineSource";
import TreeLineTooltip from "../components/MainMap/TreeLineTooltip";
import SimulationStepSliderCard from "../components/Simulation/SimulationStepSliderCard";
import SideContent from "../layout/desktop/SideContent";
import SideTreeDetailCard from "../components/TreeLines/SideTreeDetailCard";
import Footer from "../layout/Footer";
import Summary from "../components/Summary/Summary";
import TreeShadeSource from "../components/MainMap/TreeShadeSource";
import { simulationIsTouched } from "../appState/simulationSignals";
import { hasData } from "../appState/geoJsonSignals";
import MainActionCard from "../components/MainActionCard/MainActionCard";
import ReferenceAreaSource from "../components/MainMap/ReferenceAreaSource";
import ResultContent from "../layout/desktop/ResultContent";
import ResultActionCard from "../components/Results/ResultActionCard";
import { ActivePage, activePage } from "../appState/appViewSignals";
import { referenceArea } from "../appState/referenceAreaSignals";
import DistanceMeasurementsSource from "../components/MainMap/DistanceMeasurmentsSource";
import MapToolsCard from "../components/MapTools/MapToolsCard";
import SideLineDetailCard from "../components/TreeLines/SideLineDetailCard";
import { NavigationControl } from "react-map-gl";
import Glossary from "../components/Glossary/Glossary";
import BLossomsLocationsSource from "../components/MainMap/BlossomsLocationsSource";
import LegalSummaryCard from "../components/LegalFramework/LegalSummaryCard";
import AppHeader from "../layout/desktop/AppHeader";
import StandortCard from "../components/Standort/StandortCard";
import { useEffect } from "react";
import posthog from "posthog-js";
import LegalFramework from "../components/LegalFramework/LegalFramework";
import CopyPasteSource from "../components/MainMap/CopyPasteSource";
import ProjectManagementButton from "../components/Project/ProjectManagementButton";
import AppVersion from "../components/AppVersion/AppVersion";

const DesktopMain: React.FC = () => {

  const handleTabChange = (event: React.SyntheticEvent, newValue: ActivePage) => {
    activePage.value = newValue;
  };

  const isPrinting = useMediaQuery('print')

  useEffect(() => {
    posthog.capture('$pageview')
  }, [])

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      { isPrinting ? null : (
        <AppHeader rightSlot={<ProjectManagementButton variant="text" color="primary" size="small" />}>
          {
          referenceArea.value.features.length > 0 ? (  
            <Tabs value={activePage.value} onChange={handleTabChange}>
              <Tab label="Karte" value="map" icon={<Map />} iconPosition="start" />
              <Tab label="Zusammenfassung" value="summary" icon={<Summarize />} iconPosition="start" disabled={!hasData.value} />
              <Tab label="Rechtliches" value="legal" icon={<Checklist />} iconPosition="start" />
              <Tab label="Glossar" value="glossary" icon={<HelpCenter />} iconPosition="start" />
            </Tabs>
          ) : null }
        </AppHeader>
      )}
      {/* print release note */}
      { isPrinting ? null : <AppVersion /> }
      
      { activePage.value === "map" ? (
      // This needs to be 100vh - 72px for header - 32px for footer
      <Box width="100vw" height="calc(100vh - 104px)" m="0" p="0" display="flex">
          {/* add the simulation slider */}
          { hasData.value || simulationIsTouched.value ? <SimulationStepSliderCard /> : null}

          <SideContent>
            <MapToolsCard />
            <StandortCard />
            <MainActionCard />
            <LegalSummaryCard />
            <SideTreeDetailCard />
            <SideLineDetailCard />
          </SideContent>
          

          <MainMap mapId="desktop">
            <NavigationControl position="bottom-right" visualizePitch />
            <TreeLineSource />
            <TreeShadeSource /> 
            <TreeLineTooltip />
            <BLossomsLocationsSource />
            <ReferenceAreaSource />
            <DistanceMeasurementsSource />
            <CopyPasteSource />
          </MainMap>

          <ResultContent>
            <ResultActionCard />
          </ResultContent>
        </Box>
      ) : null }
      
      { activePage.value === "summary" ? (
        <Summary />
      ) : null }

      { activePage.value === "glossary" ? (
        <Glossary />
      ) : null }

      { activePage.value === "legal" ? (
        <LegalFramework />
      ) : null }

      {isPrinting ? null : <Footer /> }
    </Box>
  );
};

export default DesktopMain;
