import { Avatar, List, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material"
import { useComputed } from "@preact/signals-react"
import { calculatedTreeLineFeatures } from "../../appState/treeLineSignals"
import { treeLocationFeatures } from "../../appState/geoJsonSignals"
import { editTreeLineId } from "../../appState/treeLocationSignals"
import { flyTo } from "../MainMap/MapObservableStore"
import { setDetailId } from "../../appState/sideContentSignals"
import { PiTree } from "react-icons/pi"
import { EditLocation } from "@mui/icons-material"
import { germanSpecies } from "../../appState/backendSignals"

const SingleTreeOverview: React.FC = () => {
    // get the list of all tree location and filter for the trees that are not part of a claculatedTreeLine 
    const singleTrees = useComputed(() => {
        // map the treeLineIds
        const allTreeLineIds = calculatedTreeLineFeatures.value.map(l => l.properties.id)

        // filter the tree locations
        const singles = treeLocationFeatures.value.filter(tree => tree.properties.treeLineId && !allTreeLineIds.includes(tree.properties.treeLineId))

        return singles
    })

    // create a handler to select a single tree
    const handleSelect = (index: number) => {
        // ok, at first we need to find the correct single tree
        const tree = singleTrees.value[index]
        
        // set the id of the tree to edit
        editTreeLineId.value = tree.properties.treeLineId!
        
        // get the position of the tree
        const treeCenter = tree.geometry.coordinates 
        
        // fly to the tree
        flyTo({
            center: {lon: treeCenter[0], lat: treeCenter[1]}
        })

        setDetailId({treeId: tree.properties.id, lineId: undefined})
    }

    return <>
        <List sx={{width: '100%', bgcolor: 'background.paper'}}>
            { singleTrees.value.map((tree, idx) => (
                <ListItemButton
                    key={idx}
                    sx={{width: '100%'}}
                    onClick={() => handleSelect(idx)}
                    selected={editTreeLineId.value === tree.properties.id}
                >
                    
                    <ListItemAvatar>
                        <Avatar>
                            {editTreeLineId.value === tree.properties.treeLineId ? <EditLocation /> : <PiTree />}
                        </Avatar>
                    </ListItemAvatar>

                    <ListItemText 
                        primary={germanSpecies.value[tree.properties.treeType]}
                        secondary={`${tree.properties.age} Jahre`}
                    />
                </ListItemButton>

            )) }
        </List>
    </>
}

export default SingleTreeOverview