import { appView, AppView, treePalette } from "../../appState/appViewSignals";
import { referenceArea, ReferenceArea } from "../../appState/referenceAreaSignals";
import { rawStaticData, StaticSummaryData } from "../../appState/summarySignals";
import { RawTreeLocation } from "../../appState/tree.model";
import { nanoid } from "nanoid";
import { ViewState, viewState } from "../../appState/mapSignals";
import { editAge, editHarvestAge, editPruned, editTreeLineId, rawTreeLocationSeedData } from "../../appState/treeLocationSignals";
import { previousStep, seasonMonth, simulationStep, step } from "../../appState/simulationSignals";
import { batch } from "@preact/signals-react";

interface Project {
    // project settings
    id: string;
    name: string;

    staticAttributes: StaticSummaryData;

    // save the map view state
    appState: {
        appView: AppView
        treePalette: string[]
        activePage: 'map'
        viewState: ViewState
    }

    // main data
    referenceArea: ReferenceArea

    // raw seed data
    treeLocations: RawTreeLocation[]

    // last edit settings
    lastEdit: {
        age: number,
        harvestAge: number,
        treeLineId: string,
        pruned: boolean
    }

    // simulation data
    simulation: {
        step: number,
        previousStep: number,
        seasonMonth: number
    }
}

export const createProjectSnapshot = (): Project => {
    return {
        id: nanoid(12),
        name: rawStaticData.value.systemTitle,
        staticAttributes: rawStaticData.value,
        appState: {
            appView: appView.value,
            treePalette: treePalette.value,
            activePage: 'map',
            viewState: viewState.value
        },
        referenceArea: referenceArea.value,
        treeLocations: rawTreeLocationSeedData.value,
        lastEdit: {
            age: editAge.value,
            harvestAge: editHarvestAge.value,
            treeLineId: editTreeLineId.value,
            pruned: editPruned.value
        },
        simulation: {
            step: simulationStep.value.current,
            previousStep: simulationStep.value.previous,
            seasonMonth: seasonMonth.value
        }
    }
}

export const readProjectFromFile = (file: File | undefined): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (!file) {
            reject()
        } else {
            // create a new file reader
            const reader = new FileReader()
        
            // read the file synchronously
            reader.readAsText(file)
        
            // when the file is loaded, parse the JSON
            reader.onload = () => {
                const project: Project = JSON.parse(reader.result as string)
                injectProjectSnapshot(project)
        
                // after it has been injected, return a promise
                resolve()
        }
        }
    })
}

export const injectProjectSnapshot = (project: Project) => {
    // the different project states are injected in batches
    batch(() => {
        // application settings
        appView.value = project.appState.appView
        treePalette.value = project.appState.treePalette
        viewState.value = project.appState.viewState

        // reference area
        referenceArea.value = project.referenceArea
        editAge.value = project.lastEdit.age
        editHarvestAge.value = project.lastEdit.harvestAge
        editTreeLineId.value = project.lastEdit.treeLineId
        editPruned.value = project.lastEdit.pruned

        // and static attributes
        rawStaticData.value = project.staticAttributes
    })

        //  update the simulation data
        // THIS HAS TO BE DONE, BEFORE THE TREE LOCATIONS ARE UPDATED
        // OTHERWISE THE TREES WILL ALREADY GROW WITH THE SIMULATION
        batch(() => {
            step.value = project.simulation.step
            previousStep.value = project.simulation.previousStep
            seasonMonth.value = project.simulation.seasonMonth
        })

    // next update the raw tree location data
    rawTreeLocationSeedData.value = project.treeLocations
}