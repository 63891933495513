import { Alert, AlertTitle, Box, Button, Divider,  Grid, IconButton, MenuItem, Popover, Select, Tooltip, Typography } from "@mui/material";
import MainMap from "../MainMap/MainMap";
import TreeLineSource from "../MainMap/TreeLineSource";
import SummaryTable from "./SummaryTable";
import ReferenceAreaSource from "../MainMap/ReferenceAreaSource";
import { simulationStep } from "../../appState/simulationSignals";
import { agriculturalUse, changeStaticData, staticData, summaryData } from "../../appState/summarySignals";
import { useEffect, useState } from "react";
import SimulationStepSlider from "../Simulation/SimulationStepSlider";
import TextEditField from "./TextEditField";
import ForestrySelect from "./ForestrySelect";
import SummaryLegal from "./SummaryLegal";
import ProtectionAreaSelect from "./ProtectionAreaSelect";

import { posthog } from "../../tracking"
import TreeLineTable from "./TreeLineTable";
import BiomassResultPlot from "../Results/BiomassResult/BiomassResultPlot";
import ActiveMonthsPerSpeciesPlot from "../Results/BlossomsResult/ActiveMonthsPerSpeciesPlot";
import InsectLarvaePlot from "../Results/InsectResult/InsectLarvaePlot";
import ShadeResultPlot from "../Results/ShadeResult/ShadeResultPlot";
import InsectPhanologyPlot from "../Results/InsectResult/InsectPhanologyPlot";
import BlossomsPerSpeciesPlot from "../Results/BlossomsResult/BlossomsPerSpeciesPlot";
import { biomassProperty } from "../../appState/biomassSimulationSignals";
import { Info } from "@mui/icons-material";
import SingleTreeTable from "./SingleTreeTable";

const Summary = () => {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(null)

  // on every init, inform the posthog that the Summary as reached
  // we assume this to be an conversion event
  useEffect(() => {
    posthog.capture('SummaryReached')
  }, [])

  return (<>  
    <Grid container sx={{maxWidth: "1200px", margin: "auto", boxSizing: 'border-box'}} spacing={3}>
      <Grid item xs={12}>
        <TextEditField 
          value={summaryData.value?.systemTitle || 'Mein Agroforstsystem'}
          onChange={v => changeStaticData('systemTitle', v)}
          pt={5}
          pb={2}
          variant="h4"
        />
        <Divider />
        <Box pt={3} display="flex" flexDirection="row" justifyContent="start" alignItems="center">
          <Typography variant="h6" component="span">
            Diese Zusammenfassung bezieht sich auf ihre Planung nach
          </Typography>
          <Button variant="outlined" color="success" style={{marginLeft: '1rem', marginRight: '1rem'}} onClick={e => setPopoverAnchor(e.currentTarget)}>
            {simulationStep.value.current}
          </Button>
          <Popover
            open={Boolean(popoverAnchor)} 
            onClose={() => setPopoverAnchor(null)} 
            anchorEl={popoverAnchor}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Box width="450px" px={2} pb={1} pt={2} >
              <SimulationStepSlider noMonthSlider />
            </Box>
          </Popover>
          <Typography variant="h6" component="span">
            Jahren ({new Date().getFullYear() + simulationStep.value.current }).
          </Typography>
        </Box>

      </Grid>
      
      
      <Grid item xs={12} lg={6} p={2}>
        <Box sx={{ width: '100%', height: '100%', minHeight: '500px', boxSizing: 'border-box', boxShadow: '0 2px 4px rgba(128,128,128,0.5)' }}>
          <MainMap mapId="summary">
            <TreeLineSource />
            <ReferenceAreaSource />
          </MainMap>
        </Box>
      </Grid>

      <Grid container mt={1} alignItems="stretch" flexGrow={1} width="auto">
        
        <Grid item xs={6} p={1}>
          <Box sx={{ bgcolor: "grey.100", borderRadius: 2, p: 1, flexGrow: 1, flexDirection: 'column', boxSizing: 'border-box', display: 'flex', justifyContent: 'space-between', height: '100%'}}>
            <Box>
              <Typography variant="h6" m={0} pb={1}>
                Flächenübersicht
              </Typography>
              <Box sx={{ pl: 1 }}>
                <Box>
                  <Typography variant="subtitle2">Gesamtfläche:</Typography>
                  <Typography pb={1}>{`${(summaryData.value?.referenceArea)?.toFixed(0)} ha`}</Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2">Acker-/Weidefläche:</Typography>
                  <Typography pb={1}>{`${(summaryData.value?.agriculturalArea)?.toFixed(0)} ha`}</Typography>
                </Box>
              </Box>
            </Box>

            <Box>
              <Typography variant="h6" m={0} pt={3} pb={1}>
                Nutzungsart
              </Typography>
              <Box sx={{ pl: 1 }}>
                <Typography variant="subtitle2">Feldnutzung:</Typography>
                <Select
                    variant="standard"
                    size="small"
                    value={staticData.value.agriculturalUse}
                    onChange={e => changeStaticData('agriculturalUse', e.target.value)}
                >
                    {agriculturalUse.map(use => (
                        <MenuItem key={use} value={use}>{use}</MenuItem>
                    ))}
                </Select>
                </Box>
                <Box pl={1} mt={2}>
                  <Typography variant="subtitle2">Gehölznutzung:</Typography>
                  <ForestrySelect />
              </Box>
            </Box>
          </Box>
        </Grid>
        
        <Grid item xs={6} p={1} justifyContent="space-between">
          <Box sx={{ bgcolor: "grey.100", borderRadius: 2, p: 1, flexGrow: 1}}>
            <Typography variant="h6" m={0} pb={1}>
              Klima
            </Typography>
            <Box pl={1}>
              <Box>
                <Typography variant="subtitle2">Jährl. Niederschlagssumme:</Typography>
                <Typography pb={1}>{summaryData.value?.precipitationSum} mm</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2">Jahresmitteltemperatur:</Typography>
                <Typography pb={1}>{summaryData.value?.averageTemperature} °C</Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ bgcolor: "grey.100", borderRadius: 2, p: 1, flexGrow: 1, mt: 2}}>
            <Typography variant="h6" m={0} pb={1}>
              Boden
            </Typography>
            <Box pl={1}>
              {/* <Box>
                <Typography variant="subtitle2">Vorherrschende Bodenart:</Typography>
                <Typography pb={1}>{summaryData.value?.soilType}</Typography>
              </Box> */}
              <Box>
                <Typography variant="subtitle2">Bodenfeuchte:</Typography>
                <Typography pb={1}>{summaryData.value?.soilMoisture}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2">Boden-pH</Typography>
                <Typography pb={1}>{summaryData.value?.soilpH}</Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ bgcolor: "grey.100", borderRadius: 2, p: 1, flexGrow: 1, mt: 2}}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" pb={1}>
            <Typography variant="h6" component="div" m={0}>
              Schutzgebiet
            </Typography>
            <Tooltip title="Befindet sich deine Fläche in einem Schutzgebiet, sind gegebenenfalls weitere Rahmenbedingungen zu beachten. Bitte informiere dich direkt bei der zuständigen Behörde.">
              <Info color="primary" /> 
            </Tooltip>
            </Box>
            <Box pl={1}>
              <Box>
                <ProtectionAreaSelect />
              </Box>
            </Box>
          </Box>

        </Grid>

      </Grid>

      <Grid item xs={12} mt={3}>
        <Typography variant="h6">
          Planung
        </Typography>
        <Typography color={"textSecondary"} sx={{maxWidth: 600, pb: 1,}}>
          Hier siehst du eine Übersicht deines geplanten Agroforstsystems.
        </Typography>
        <Box>
          <SummaryTable />
        </Box>
        <Box mt={2}>
          <Typography color={"textSecondary"} sx={{pb: 1,}}>
            Hier siehst du eine Übersicht sämtlicher Baumreihen deines Agroforstsystems. Einzelbäume werden hier nicht angezeigt.
          </Typography>
          <TreeLineTable />
        </Box>
        <Box mt={2}>
          <Typography color={"textSecondary"} sx={{pb: 1}}>
            Hier siehst du eine Übersicht sämtlicher Einzelbäume. Individuen, die Teil einer Reihe sind, werden nicht angezeigt.
          </Typography>
          <SingleTreeTable />
        </Box>
      </Grid>

      <Grid item xs={12} mt={3}>
        <Typography variant="h6">Leistungen im Gesamtsystem</Typography>
        <Typography color={"textSecondary"} sx={{maxWidth: 600,pb: 1,}}>
          Hier findest du eine Übersicht der Leistungen deines Agroforstsystems.
        </Typography>
        
        <Grid mt={3} container spacing={4}>
        <Grid item xs={6} lg={4}>
            <Typography variant="subtitle1">Anzahl unterstützter Bienenarten</Typography>
            <InsectPhanologyPlot />
          </Grid>
          <Grid item xs={6} lg={4}>
            <Typography variant="subtitle1">
              { biomassProperty.value === 'agb' ? 'Oberirdische Biomasse' : 'Oberirdischer Kohlenstoff'}
            </Typography>
            <BiomassResultPlot />
          </Grid>
          <Grid item xs={6} lg={4}>
            <Typography variant="subtitle1">Beschattung der landwirtschaftlichen Fläche</Typography>
            <ShadeResultPlot />
          </Grid>
          <Grid item xs={6} lg={4}>
            <Typography variant="subtitle1">Anzahl unterstützer Larven pro Jahr</Typography>
            <InsectLarvaePlot />
          </Grid>
          <Grid item xs={6} lg={4}>
            <Typography variant="subtitle1">Blühangebot nach Art</Typography>
            <BlossomsPerSpeciesPlot />
          </Grid>
          <Grid item xs={6} lg={4}>
            <Typography variant="subtitle1">Blüh- bzw. Aktivitätszeitraum</Typography>
            <ActiveMonthsPerSpeciesPlot />
          </Grid>
        </Grid>

      </Grid>

      <Grid item xs={12} mt={3}>
        {/* <Typography pt={6} pb={2} variant="h4">
          Informationen
        </Typography> */}
        <Divider />
        <Typography pt={3} variant="h6">
          Rechtliche Rahmenbedingungen
        </Typography>
        <Typography color={"textSecondary"} sx={{maxWidth: 600,pb: 1,}}>
          Wichtige Rechtliche Rahmenbedingen zur Anlage ihres Agroforstsystemes
        </Typography>
      </Grid>

      <Grid item xs={12} p={1}>
        <SummaryLegal />
        <Typography pt={3} variant="h6">
          Haftungsausschluss
        </Typography>
        <Box mb={2}>
            <Alert severity="error">
                <AlertTitle>Rechtlicher Hinweis</AlertTitle>
                Die Berechnung der Förderbedingungen erfolgt auf Basis der gesetzlichen Regelungen von Juni 2024.
                Sowohl die Seite agroforst-planungstool.de als auch die Firma hydrocode GmbH stellen diese Informationen als 
                reine Orientierungshilfe zur Verfügung.
                Die Angaben sind ohne Gewähr und ersetzen keine rechtliche Beratung. 
                Förderansprüche müssen durch die zuständigen Behörden geprüft werden, bei Nicht-Gewährung ist hydrocode GmbH nicht haftbar.
            </Alert>
        </Box>
      </Grid>

    </Grid>
  </>);
};

export default Summary;
