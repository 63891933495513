import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Tooltip } from "@mui/material"
import { ErrorOutline, ExpandMore, FitScreenOutlined, VisibilityOff } from "@mui/icons-material"
import { fitReferenceArea, isReferenceAreaEditable, referenceArea } from "../../appState/referenceAreaSignals"
import { useSignal } from "@preact/signals-react"
import { maximumDistances, minimumDistanceArea, showDistances } from "../../appState/legalSignals"
import { rawTreeFeatures } from "../../appState/treeLocationSignals"
import { enableMoveKnobsTrigger } from "../../appState/copyPaseSignals"
import { SlCursorMove } from "react-icons/sl"
import { CgDebug } from "react-icons/cg"
import { TbPolygon, TbPolygonOff, TbRulerMeasure } from "react-icons/tb"
import { treeLineAreaFeatures } from "../../appState/treeLineSignals"
import { distanceRulerTrigger } from "../../appState/distanceSignals"

const DEV_MODE = process.env.REACT_APP_DEV_MODE === "true"

const MapToolsCard: React.FC = () => {
    // create a local signal to handle open
    const open = useSignal(true)

    return <>
        <Accordion 
            // expanded={activeCard.value === 'map-tools'} 
            // onChange={() => handleCardToggle('map-tools')}
            expanded={open.value}
            onChange={() => open.value = !open.peek()}
            disableGutters
        >
            <AccordionSummary expandIcon={<ExpandMore />}>
                Kartenwerkzeuge
            </AccordionSummary>
            <AccordionDetails>
                <Box>
                    <Box display="flex" flexDirection="row">
                        { DEV_MODE ? (
                            <IconButton onClick={() => console.log(rawTreeFeatures.peek())} >
                                <CgDebug />
                            </IconButton>
                        ) : null }

                        <IconButton
                            color={distanceRulerTrigger.value ? 'error' : 'primary' }
                            disabled={referenceArea.value.features.length === 0}
                            onClick={() => distanceRulerTrigger.value = !distanceRulerTrigger.value}
                        >
                            <TbRulerMeasure />
                        </IconButton>

                        <IconButton 
                            color="primary" 
                            onClick={() => fitReferenceArea()} 
                            disabled={referenceArea.value.features.length === 0}
                        >
                            <FitScreenOutlined />
                        </IconButton>

                        {/* show MoveKnobs on the map */}
                        <IconButton 
                            color={enableMoveKnobsTrigger.value ? 'primary' : 'default'}
                            onClick={() => enableMoveKnobsTrigger.value = !enableMoveKnobsTrigger.peek()}
                            disabled={treeLineAreaFeatures.value.length === 0}
                        >    
                            <SlCursorMove />
                        </IconButton>

                        {/* reference area edit */}
                       <Tooltip title={isReferenceAreaEditable.value ? 'Referenzfläche bearbeiten beenden' : 'Bearbeite die Referenzfläche des Agroforstsystems'}>
                        <IconButton
                                color={isReferenceAreaEditable.value ? 'error' : 'primary'}
                                onClick={() => isReferenceAreaEditable.value = !isReferenceAreaEditable.peek()}
                                disabled={referenceArea.value.features.length === 0}
                            >
                                { isReferenceAreaEditable.value ? <TbPolygonOff /> : <TbPolygon /> }
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Zeige Abstandshinweise für Förderbedingungen">
                            <IconButton 
                                color={minimumDistanceArea.value.features.length + maximumDistances.value.features.length > 0 ? 'primary' : 'default'}
                                disabled={minimumDistanceArea.value.features.length + maximumDistances.value.features.length === 0}
                                onClick={() => showDistances.value = !showDistances.peek()}
                            >
                                {showDistances.value ? <VisibilityOff /> : <ErrorOutline />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    </>
}

export default MapToolsCard